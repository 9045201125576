#s10 {
  height: 100vh;
  width: 100vw;

  .fp-bg {
    background-image: url("../img/img-05.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }
}
