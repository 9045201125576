#s11 {
  background: url("../img/bg-2.png");
  background-size: cover;
}

.min-standards {
  @include reset-list();
  @include flex(row, nowrap, space-evenly, center);
  position: relative;
  margin-top: 40px;
}

.circle {
  border: 1px solid $white;
  background-color: #301664;
  color: $white;
  text-align: center;
  border-radius: 50%;
  font-weight: 700;
  padding: 20px;
  height: 80px;
  width: 80px;
  cursor: pointer;
  @include flex(row, nowrap, center, center);
  &--1 {
    &:hover {
      background-color: #ffcf00;
      color: $black;
    }
  }
  &--2 {
    &:hover {
      background-color: #94c01a;
      color: $black;
    }
  }
  &--3 {
    &:hover {
      background-color: #1371b8;
    }
  }
}
