.slide__container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  @include md-up {
    padding: 40px;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
  }
  @include lg-up {
    padding: 80px;
  }
  height: 100%;
}

.slide__header {
  font-size: 14px;
  font-weight: 800;
  margin: 0 0 10px 0;

  @include md-up {
    font-size: 36px;
    margin: 0 0 40px 0;
  }

  @include lg-up {
    margin: 0 0 60px 0;
  }
}

.slide__content {
  font-size: 10px;

  p {
    margin: 5px 0;
  }
  @include md-up {
    font-size: 16px;
    p {
      margin: 20px 0;
    }
  }
}

.fp-arrow {
  top: unset;
  bottom: 15px;
}
