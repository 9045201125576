/*
  *
  * All colours should be generated by using the name that color website and use the hexidecimal value.
  * All named colours should be in lowercase
  * Where you must use an RGB value then generate the colour name and use the suffix RGB, add both values in this document, eg: $black: #000000; and $blackRGB: 000,000,000;
  * http://chir.ag/projects/name-that-color/#6195ED
  *
*/

////
/// @group Settings
////

/// <svg xmlns="https://www.w3.org/2000/svg" width="15" height="15"><rect fill="#ffffff" x="0" y="0" width="100%" height="100%" stroke="black"></svg> It's as plain as it can be, it's white
/// @name white
/// @example scss - Basic Scss usage
/// .foo { color: $white; }
/// @example css - Expected CSS
/// .foo { color: #ffffff; }
/// @author Rob Watson
$white: #ffffff;

/// <svg xmlns="https://www.w3.org/2000/svg" width="15" height="15"><rect fill="#000000" x="0" y="0" width="100%" height="100%" stroke="black"></svg> It's as dark as it can be, it's black.
/// This should probably not be used for text on a white background
/// @name black
/// @author Rob Watson
/// @example scss - Basic Scss usage
/// .foo { color: $black; }
/// @example css - Expected CSS
/// .foo { color: #000000; }
$black: #000000;

/// <svg xmlns="https://www.w3.org/2000/svg" width="15" height="15"><rect fill="#222222" x="0" y="0" width="100%" height="100%" stroke="black"></svg>
///
/// @name mine-shaft
/// @author Rob Watson
/// @example scss - Basic Scss usage
/// .foo { color: $mine-shaft; }
/// @example css - Expected CSS
/// .foo { color: #222222; }

$mine-shaft: #222222;
