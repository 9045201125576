#s2 {
  height: 100vh;
  width: 100vw;

  .fp-bg {
    background-image: url("../img/img-01.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
}
