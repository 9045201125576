//// 
/// @group _Config
/// @author Rob Watson
////

/// Root font size for the entire website, this is set on the html tag itself
/// @name root-font-size
/// @example Scss - Basic Scss usage 
/// html { font-size: $root-font-size; }
/// @example css - Expected CSS
/// html { font-size: 65.5%; }
///
$root-font-size: 65.5%;

/// Base font family for the entire website, this is set on the body tag
/// @name base-font-family
$base-font-family: 'Oswald', sans-serif;

/// Base font weight for the entire website, this is set on the body tag
/// @name base-font-weight
/// @example Scss - Basic Scss usage 
/// body { font-weight: $base-font-weight; }
/// @example css - Expected CSS
/// body { font-weight: 300; }
$base-font-weight: 300;

/// Base colours for anything with no background applied
/// @name base-colour-background
/// @example Scss - Basic Scss usage 
/// .foo { background: $base-colour-background; }
/// @example css - Expected CSS
/// .foo { background: #ffffff; }
$base-colour-background: $white;

/// Base colours for anything with no colour applied
/// @name base-colour-text
/// @example Scss - Basic Scss usage 
/// .foo { color: $base-colour-text; }
/// @example css - Expected CSS
/// .foo { color: #222222; }
$base-colour-text: $mine-shaft;

/// This roughly match that used by Bootstrap's xs breakpoint
/// @name screen-xs-min
$screen-xs-min: 425px;

/// This roughly match that used by Bootstrap's sm breakpoint
/// @name screen-sm-min
$screen-sm-min: 576px;

/// This roughly match that used by Bootstrap's md breakpoint
/// @name screen-md-min
$screen-md-min: 768px;

/// This roughly match that used by Bootstrap's lg breakpoint
/// @name screen-lg-min
$screen-lg-min: 992px;

/// This roughly match that used by Bootstrap's xl breakpoint
/// @name screen-xl-min
$screen-xl-min: 1200px;
