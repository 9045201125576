body {
  background: url("../img/bg-1.jpg");
}

// .fp-watermark {
//   display: none;
// }

.alpha {
  list-style: lower-alpha;
}

// https://codepen.io/vaibhavarora/pen/xmpxjp
.bg-circle {
  position: absolute;
  border-radius: 50%;
  background: white;
  animation: ripple 15s infinite;
  box-shadow: 0px 0px 1px 0px #508fb9;

  &--1 {
    opacity: 0.8;
  }

  &--2 {
    opacity: 0.6;
  }

  &--3 {
    opacity: 0.5;
  }

  &--4 {
    opacity: 0.4;
  }

  &--5 {
    opacity: 0.3;
  }

  &--sm {
    width: 50px;
    height: 50px;
    @include md-up {
      width: 100px;
      height: 100px;
    }
    @include lg-up {
      width: 200px;
      height: 200px;
    }
  }

  &--md {
    width: 100px;
    height: 100px;

    @include md-up {
      width: 200px;
      height: 200px;
    }
    @include lg-up {
      width: 400px;
      height: 400px;
    }
  }

  &--lg {
    width: 150px;
    height: 150px;

    @include md-up {
      width: 300px;
      height: 300px;
    }
    @include lg-up {
      width: 600px;
      height: 600px;
    }
  }

  &--xl {
    width: 200px;
    height: 200px;

    @include md-up {
      width: 400px;
      height: 400px;
    }
    @include lg-up {
      width: 800px;
      height: 800px;
    }
  }

  &--xxl {
    width: 250px;
    height: 250px;

    @include md-up {
      width: 500px;
      height: 500px;
    }
    @include lg-up {
      width: 1000px;
      height: 1000px;
    }
  }
  /** Top Left **/
  &--sm--tl {
    left: -25px;
    top: -25px;
    @include md-up {
      left: -50px;
      top: -50px;
    }
    @include lg-up {
      left: -100px;
      top: -100px;
    }
  }

  &--md--tl {
    left: -50px;
    top: -50px;

    @include md-up {
      left: -100px;
      top: -100px;
    }
    @include lg-up {
      left: -200px;
      top: -200px;
    }
  }

  &--lg--tl {
    left: -75px;
    top: -75px;

    @include md-up {
      left: -150px;
      top: -150px;
    }
    @include lg-up {
      left: -300px;
      top: -300px;
    }
  }

  &--xl--tl {
    left: -100px;
    top: -100px;
    @include md-up {
      left: -200px;
      top: -200px;
    }
    @include lg-up {
      left: -400px;
      top: -400px;
    }
  }

  &--xxl--tl {
    left: -125px;
    top: -125px;

    @include md-up {
      left: -250px;
      top: -250px;
    }
    @include lg-up {
      left: -500px;
      top: -500px;
    }
  }
  /** Top Right **/
  &--sm--tr {
    right: -25px;
    top: -25px;

    @include md-up {
      right: -50px;
      top: -50px;
    }
    @include lg-up {
      right: -100px;
      top: -100px;
    }
  }

  &--md--tr {
    right: -50px;
    top: -50px;

    @include md-up {
      right: -100px;
      top: -100px;
    }
    @include lg-up {
      right: -200px;
      top: -200px;
    }
  }

  &--lg--tr {
    right: -75px;
    top: -75px;

    @include md-up {
      right: -150px;
      top: -150px;
    }
    @include lg-up {
      right: -300px;
      top: -300px;
    }
  }

  &--xl--tr {
    right: -100px;
    top: -100px;

    @include md-up {
      right: -200px;
      top: -200px;
    }
    @include lg-up {
      right: -400px;
      top: -400px;
    }
  }

  &--xxl--tr {
    right: -125px;
    top: -125px;

    @include md-up {
      right: -250px;
      top: -250px;
    }
    @include lg-up {
      right: -500px;
      top: -500px;
    }
  }
  /** Bottom Right **/
  &--sm--br {
    right: -25px;
    bottom: -25px;

    @include md-up {
      right: -50px;
      bottom: -50px;
    }
    @include lg-up {
      right: -100px;
      bottom: -100px;
    }
  }

  &--md--br {
    right: -50px;
    bottom: -50px;

    @include md-up {
      right: -100px;
      bottom: -100px;
    }
    @include lg-up {
      right: -200px;
      bottom: -200px;
    }
  }

  &--lg--br {
    right: -75px;
    bottom: -75px;

    @include md-up {
      right: -150px;
      bottom: -150px;
    }
    @include lg-up {
      right: -300px;
      bottom: -300px;
    }
  }

  &--xl--br {
    right: -100px;
    bottom: -100px;

    @include md-up {
      right: -200px;
      bottom: -200px;
    }
    @include lg-up {
      right: -400px;
      bottom: -400px;
    }
  }

  &--xxl--br {
    right: -125px;
    bottom: -125px;

    @include md-up {
      right: -250px;
      bottom: -250px;
    }
    @include lg-up {
      right: -500px;
      bottom: -500px;
    }
  }
  /** Bottom Left */
  &--sm--bl {
    left: -25px;
    bottom: -25px;
    @include md-up {
      left: -50px;
      bottom: -50px;
    }
    @include lg-up {
      left: -100px;
      bottom: -100px;
    }
  }

  &--md--bl {
    left: -50px;
    bottom: -50px;
    @include md-up {
      left: -100px;
      bottom: -100px;
    }
    @include lg-up {
      left: -200px;
      bottom: -200px;
    }
  }

  &--lg--bl {
    left: -75px;
    bottom: -75px;
    @include md-up {
      left: -150px;
      bottom: -150px;
    }
    @include lg-up {
      left: -300px;
      bottom: -300px;
    }
  }

  &--xl--bl {
    left: -100px;
    bottom: -100px;
    @include md-up {
      left: -200px;
      bottom: -200px;
    }
    @include lg-up {
      left: -400px;
      bottom: -400px;
    }
  }

  &--xxl--bl {
    left: -125px;
    bottom: -125px;
    @include md-up {
      left: -250px;
      bottom: -250px;
    }
    @include lg-up {
      left: -500px;
      bottom: -500px;
    }
  }
}

@keyframes ripple {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.8);
  }
}

.partners {
  @include reset-list();

  width: 100%;
  margin-top: 20px;
  text-align: center;

  @include md-up {
    @include flex(row, nowrap, space-between, flex-start);
    gap: 10px;
  }
}

.partner {
  margin-bottom: 20px;
}
.partnership-with {
  color: #ccc;
  margin-top: 40px;
}

.partner__logo {
  border: 1px solid #999;
  display: inline-block;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.15);

  margin-top: 20px;
}

.md-up-only {
  display: none;
  @include md-up {
    display: block;
  }
}

.mobile-only {
  @include md-up {
    display: none;
  }
}
