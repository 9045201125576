#s0 {
  background: url("../img/section-01.png");
  background-size: cover;
}

.twg-logo {
  position: absolute;
  top: 0;
  right: 10px;
  background: $white;
  padding: 10px;
  -webkit-border-bottom-right-radius: 8px;
  -webkit-border-bottom-left-radius: 8px;
  -moz-border-radius-bottomright: 8px;
  -moz-border-radius-bottomleft: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  img {
    width: 140px;
    @include md-up {
      width: 180px;
    }
  }
}

.page-header {
  position: relative;
  padding: 10px;
  font-family: "Roboto", sans-serif;
  overflow: hidden;

  @include md-up {
    width: 70%;
  }

  span {
    background-color: #f0aa31;
    padding: 10px 20px 10px 0;
    font-size: 28px;
    font-weight: 900;
    line-height: 54px;
    color: #ffffff;
    box-shadow: 10px 0 0px 0px #f0aa31, -10px 0 0px 0px #f0aa31;
    margin: 0;

    @include md-up {
      font-size: 60px;
      line-height: 90px;
    }
  }
}

.page-sub-header {
  position: relative;
  font-family: "Roboto", sans-serif;
  padding: 10px;

  overflow: hidden;

  @include md-up {
    width: 70%;
  }

  span {
    background-color: #f0aa31;
    padding: 10px 20px 10px 0;
    font-size: 22px;
    font-weight: 400;
    line-height: 54px;
    color: #ffffff;
    box-shadow: 10px 0 0px 0px #f0aa31, -10px 0 0px 0px #f0aa31;
    margin: 0;

    @include md-up {
      font-size: 48px;
      line-height: 60px;
    }
  }
}

.read-time {
  color: #ffffff;
  box-shadow: 10px 0 0px 0px #f0aa31, -10px 0 0px 0px #f0aa31;
  font-size: 14px;
  background-color: #f0aa31;
  margin: 0 10px;
}
