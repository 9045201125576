.full-belly {
  width: 100vw;
  height: 100vh;
  padding: 20px;
  @include md-up {
    padding: 40px;
  }
  @include lg-up {
    padding: 0 80px;
  }
  svg {
    margin-top: -10px;
  }
}

.panel-intro {
  display: none;
  &--active {
    display: block;
  }
}

.modality {
  display: none;
  &--active {
    display: block;
  }
}

.profiles {
  @include reset-list();
  margin-top: 40px;
}

.profile {
  color: $white;
  padding: 5px 10px;
  margin: 5px 0;
  text-align: center;
  border-radius: 5px;
  &--1 {
    background-color: #1371b8;
  }
  &--2 {
    background-color: #a2165b;
  }
  &--3 {
    background-color: #301664;
  }
  &--4 {
    background-color: #94c01a;
  }
}
