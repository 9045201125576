.modal {
  display: none;

  &--active {
    display: block;
    position: fixed;
    width: calc(100vw - 40px);
    height: calc(100vh - 40px);
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    z-index: 8;
    background: $white;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.15);

    @include md-up {
      left: 50%;
      top: 45%;
      width: 75%;
      min-height: 60vh;
      height: auto;
      max-height: 75vh;
      bottom: unset;
      right: unset;
      transform: translate(-50%, -45%);
      bottom: unset;
    }

    @include lg-up {
      width: 50%;
    }
  }
}

.modal__header {
  @include flex(row, nowrap, flex-start, flex-end);

  position: relative;
  color: $white;
  padding: 20px;
  min-height: 60px;
  text-transform: uppercase;

  @include md-up {
    height: 100px;
  }

  &--0 {
    background-color: #301664;
  }
  &--1 {
    background-color: #1371b8;
  }
  &--2 {
    background-color: #a2165b;
  }
  &--3 {
    background-color: #301664;
  }
  &--4 {
    background-color: #94c01a;
  }
}

.modal__copy {
  //position: relative;

  height: 100%;
  font-size: 14px;

  @include md-up {
    font-size: 16px;
  }
}
.modal__inner-copy {
  overflow: scroll;
  //position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 20px;
}

.modal__close {
  position: absolute;
  background: none;
  border: none;
  right: 10px;
  top: 10px;
  width: 32px;
  height: 32px;
  z-index: 5;

  @include md-up {
    right: 30px;
    top: 30px;
  }

  &:hover {
    opacity: 1;
  }
  &:before,
  &:after {
    position: absolute;
    top: 0;
    left: 15px;
    content: " ";
    height: 33px;
    width: 2px;
    background-color: $white;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

.modal__navigation {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.modal-nav {
  background: #301664;
  color: $white;
  width: 100%;
  cursor: pointer;
  @include flex(row, nowrap, center, center);

  &--1 {
    &:hover {
      background-color: #ffcf00;
      color: $black;
    }
  }
  &--2 {
    &:hover {
      background-color: #94c01a;
      color: $black;
    }
  }
  &--3 {
    &:hover {
      background-color: #1371b8;
    }
  }
  &--current--1 {
    background-color: #ffcf00;
  }
  &--current--2 {
    background-color: #94c01a;
  }
  &--current--3 {
    background-color: #1371b8;
  }
}
