////
/// @group Vendor
/// @author The Bootstrap Authors
////

/*!
 * Bootstrap Grid v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/// Basic settings required for Bootstrap
/// @name html
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

/// Basic box sizing on elements before and after required for Bootstrap
/// @name *,*::before,*::after
*,
*::before,
*::after {
  box-sizing: inherit;
}

@import "./node_modules/bootstrap/scss/bootstrap";

/// This is a custom class used to extend bootstrap and center the rows while still allowing fullwidth backgrounds.
/// It is applied on the same level as the row
/// @name .row--central
/// @author Rob Watson
/// @example <article class="row row--central"></article>
.row--central {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  @include sm-up {
    max-width: 540px;
  }
  @include md-up {
    max-width: 720px;
  }
  @include lg-up {
    max-width: 960px;
  }
  @include xl-up {
    max-width: 1140px;
  }
}
