////
/// @group Tools
/// @author Rob Watson
////

/// Clear fix to help with floated elements (if used)
/// @name cf
/// @example scss - Basic Scss usage
/// .foo { @include cf; }
/// @example css - Expected CSS
/// .foo:after { content: ""; display: table; clear: both; }
///
@mixin cf {
  
  &:after {
    
    content: "";
    display: table;
    clear: both;

  }

}

/// Allows you to convert a container into a flex layout
/// @name flex
/// @link https://css-tricks.com/snippets/css/a-guide-to-flexbox/
/// @param {string} $direction [row] - Accepts: row | row-reverse | column | column-reverse
/// @param {string} $wrap [nowrap] - Accepts: nowrap | wrap | wrap-reverse
/// @param {string} $justify [space-evenly] - Accepts: flex-start | flex-end | center | space-between | space-around | space-evenly | start | end | left | right
/// @param {string} $align [flex-start] - Accepts: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end
/// @example scss - Basic Scss usage
/// .foo { @include flex(); }
/// @example css - Expected CSS
/// .foo { display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-evenly; align-items: flex-start; }
/// @example scss - Customised Scss usage
/// .foo { @include flex(row-reverse, wrap-reverse, space-evenly, baseline ); }
/// @example css - Expected CSS
/// .foo { display: flex; flex-direction: row-reverse; flex-wrap: wrap-reverse; justify-content: space-evenly; align-items: baseline; }
///
@mixin flex($direction: row, $wrap: nowrap, $justify: space-evenly, $align: flex-start){
  display: flex;
  flex-direction: $direction;
  flex-wrap: $wrap;
  justify-content: $justify;
  align-items: $align;
}

/// Font sizes in REMs with PX backup, This is calculated as 1 Rem = 0.0625px (approx)
/// @name font-size
/// @link http://www.standardista.com/px-to-rem-conversion-if-root-font-size-is-16px/
/// @link https://www.ninjaunits.com/converters/pixels/pixels-rem/
/// @param {int} $sizeValue [1.6] - This accepts a number value the equivalent of the size in pixels which you'd like the font size to be.
/// @example scss - Basic Scss usage
/// .foo { @include font-size(13); }
/// @example css - Expected CSS
/// .foo { font-size: 13px; font-size: 13rem; }
///
@mixin font-size($sizeValue: 1.6) {
  font-size: $sizeValue + px;
  font-size: ($sizeValue * 0.0625) + rem;
}

/// Reset the default styles on lists by removing the padding, margins and bullet points
/// @name reset-list
/// @example scss - Basic Scss usage
/// .foo { @include reset-list; }
/// @example css - Expected CSS
/// .foo { list-style-type: none; margin: 0; padding: 0; }
///
@mixin reset-list {
  list-style-type: none; 
  margin: 0; 
  padding: 0;
}
