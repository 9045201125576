.splash {
  @include flex(row, nowrap, center, center);
  background-color: $black;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  opacity: 1;

  &--inactive {
    opacity: 0;
    // -webkit-animation: fade-out 1.5s ease-out both;
    // animation: fade-out 1.5s ease-out both;
    transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
    z-index: -1;
  }
}

.twg-spinner {
  width: 100px;
  height: 100px;
  margin: 20px;
  display: inline-block;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-9-29 21:18:55
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------
 */
// @-webkit-keyframes fade-out {
//   0% {
//     opacity: 1;
//   }
//   50% {
//     opacity: 0.5;
//   }
//   100% {
//     opacity: 0;
//   }
// }
// @keyframes fade-out {
//   0% {
//     opacity: 1;
//   }
//   50% {
//     opacity: 0.5;
//   }
//   100% {
//     opacity: 0;
//   }
// }
