/// This class deals with the flash of unstyled content from our gsap animations
/// @name .gsap-fouc
/// @link https://greensock.com/fouc/
.gsap-fouc {
  visibility: hidden;
}

.section {
  @include flex(row, nowrap, center, center);
  width: 100vw;
}

.section__bg {
  background: url("../img/bg-1.jpg");
  width: 100vw;
  height: 100vh;
}

.section__container {
  @include flex(column, nowrap, center, flex-start);
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  @include md-up {
    padding: 40px;
  }
  @include lg-up {
    padding: 80px;
  }
}

.section__header {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 900;
  color: #666;
  margin: 0 0 10px 0;

  @include md-up {
    padding-right: 40px;
    font-size: 36px;
  }

  @include lg-up {
    margin: 0 0 60px 0;
  }
}

.section__header--right {
  @include lg-up {
    text-align: right;
  }
}

.section__header--mid {
  @include lg-up {
    margin: 0 0 40px 0;
  }
}

.section__copy {
  font-size: 10px;

  p {
    margin: 5px 0;
  }
  @include md-up {
    font-size: 16px;
    p {
      margin: 20px 0;
    }
  }
}

.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-9-27 20:50:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.ctas {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 40px 0;
  gap: 10px;
}

.cta {
  color: $white;
  text-decoration: none;
  background: #0057f8;
  padding: 10px 20px;
  display: inline-block;
  text-align: center;
  transition: background-color 0.5s;

  &:focus,
  &:hover {
    background-color: #7bbb42;
    color: $white;
  }
}

.cta--fixed {
  position: absolute;
  right: -30vw;
  top: 40px;

  &--active {
    right: 0;
    z-index: 100;
  }
}

.copy-cells {
  @include md-up {
    @include flex(row, nowrap, space-evenly, center);
  }
}

.blockquote {
  padding: 20px;
  font-size: 14px;
  font-weight: 900;
  color: #666;

  p {
    margin: 0;
  }

  @include md-up {
    font-size: 24px;
    padding: 40px;
    margin-left: 40px;
  }

  &--s1 {
    @include md-up {
      width: 46%;
    }
  }
}

.section__header--center {
  text-align: center;
}

.highlight {
  @include md-up {
    font-size: 75px;
    text-transform: uppercase;
    line-height: 85px;
    display: block;
  }
}
