//// 
/// @group Tools
/// @author Rob Watson
////

/// Small breakpoint, as a rough guide this will target phones in landscape and above
/// @name sm-up
/// @example scss - Basic Scss usage
/// .foo { color: $white; @include sm-up { color: $black; } }
/// @example css - Expected CSS
/// .foo { color: #ffffff; } @media (min-width: 576px) { .foo { color: #000000; } }
///
@mixin sm-up {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

/// Medium breakpoint, as a rough guide this will target tablets in portrait and above
/// @name md-up
/// @example scss - Basic Scss usage
/// .foo { color: $white; @include md-up { color: $black; } }
/// @example css - Expected CSS
/// .foo { color: #ffffff; } @media (min-width: 768px) { .foo { color: #000000; } }
///
@mixin md-up {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

/// Large breakpoint, as a rough guide this will target tablets in landscape and above
/// @name lg-up
/// @example scss - Basic Scss usage
/// .foo { color: $white; @include lg-up { color: $black; } }
/// @example css - Expected CSS
/// .foo { color: #ffffff; } @media (min-width: 992px) { .foo { color: #000000; } }
///
@mixin lg-up {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

/// Extra Large breakpoint, as a rough guide this will target large screens
/// @name xl-up
/// @example scss - Basic Scss usage
/// .foo { color: $white; @include xl-up { color: $black; } }
/// @example css - Expected CSS
/// .foo { color: #ffffff; } @media (min-width: 1200px) { .foo { color: #000000; } }
///
@mixin xl-up {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

/// Small breakpoint, as a rough guide this will target phones in landscape and below (rarely used)
/// @name sm-down
/// @example scss - Basic Scss usage
/// .foo { color: $white; @include sm-down { color: $black; } }
/// @example css - Expected CSS
/// .foo { color: #ffffff; } @media (max-width: 576px) { .foo { color: #000000; } }
///
@mixin sm-down {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

/// Medium breakpoint, as a rough guide this will target tablets in portrait and below (rarely used)
/// @name md-down
/// @example scss - Basic Scss usage
/// .foo { color: $white; @include md-down { color: $black; } }
/// @example css - Expected CSS
/// .foo { color: #ffffff; } @media (max-width: 768px) { .foo { color: #000000; } }
///
@mixin md-down {
  @media (max-width: #{$screen-md-max}) {
    @content;
  }
}

/// Large breakpoint, as a rough guide this will target tablets in landscape and below (rarely used)
/// @name lg-down
/// @example scss - Basic Scss usage
/// .foo { color: $white; @include lg-down { color: $black; } }
/// @example css - Expected CSS
/// .foo { color: #ffffff; } @media (max-width: 992px) { .foo { color: #000000; } }
///
@mixin lg-down {
  @media (max-width: #{$screen-lg-max}) {
    @content;
  }
}
