.section--parallax {
  display: block;
  flex-direction: unset;
  .fp-tableCell {
    width: 100vw;
  }
}

.fp-bg {
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden; /* Chrome, Safari, Opera */
  backface-visibility: hidden;
  background-position: center;
  @include lg-up {
    background-size: cover;
  }

  &:before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
}
