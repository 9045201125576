.percent {
  @include md-up {
    font-size: 150px;
    line-height: 150px;
    -webkit-animation: pulsate 3s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation: pulsate 3s ease-out;
    animation-iteration-count: infinite;
    opacity: 0.5;
  }
}

@-webkit-keyframes pulsate {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes pulsate {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
